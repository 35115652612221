import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual } from 'react-redux'
import clsx from 'clsx'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchFeatureFlags from '@redux/thunks/application/fetchFeatureFlags'
import fetchCollection from '@redux/thunks/collections/fetchCollection'
import fetchStoreCollections from '@redux/thunks/collections/fetchStoreCollections'
import fetchBusinesses from '@redux/thunks/stores/fetchBusinesses'
import fetchStore from '@redux/thunks/stores/fetchStore'
import { INFO } from 'config/alert'
import Debug from 'helpers/debug'
import CollectionActions from 'components/Collections/CollectionActions'
import CollectionDates from 'components/Collections/CollectionDates'
import CollectionDetails from 'components/Collections/CollectionDetails'
import Alert from 'components/common/Alert/Alert'
import LoadingSpinner from 'components/common/LoadingSpinner'
import StoreInfo from 'components/common/StoreInfo'
import { Stack } from 'components/layout/primitives'
import styles from './MyCollectionWrapper.module.scss'

const debug = Debug('components:collections:my-collection-wrapper')

type Props = {
  collectionId: number
}

const MyCollectionWrapper = ({ collectionId }: Props) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  const {
    storeId,
    type: collectionType,
    endDate: collectionEndDate,
  } = useAppSelector((state) => state.collections.items[collectionId] ?? {}, shallowEqual)
  const { businessId, location } = useAppSelector(
    (state) => state.stores.items[storeId] ?? {},
    shallowEqual,
  )
  const { logo } = useAppSelector(
    (state) => state.stores.businesses[businessId] ?? {},
    shallowEqual,
  )

  useEffect(() => {
    if (collectionId !== undefined) {
      dispatch(fetchCollection(collectionId)).catch(debug)
    }
  }, [collectionId])

  useEffect(() => {
    if (storeId !== undefined) {
      dispatch(fetchStore(storeId)).catch(debug)
    }
  }, [storeId])

  useEffect(() => {
    if (storeId !== undefined) {
      dispatch(fetchStoreCollections({ storeId })).catch(debug)
    }
  }, [storeId])

  useEffect(() => {
    if (businessId !== undefined) {
      dispatch(fetchBusinesses([businessId])).catch(debug)
    }
  }, [businessId])

  useEffect(() => {
    dispatch(fetchFeatureFlags()).catch(debug)
  }, [])

  if (businessId === undefined || logo === undefined) {
    return <LoadingSpinner containerHeight="400px" />
  }

  return (
    <div className={clsx('card', styles.card)}>
      <div className={clsx('card-content', styles.content)}>
        <StoreInfo
          storeId={storeId}
          logoUrl={logo.url}
          location={location}
        />

        <Stack gap="1.25rem">
          <CollectionDates collectionId={collectionId} />
          <CollectionDetails collectionId={collectionId} />
        </Stack>

        {collectionType !== 'ongoing' && (
          <Alert
            className={styles.unassignAlert}
            variant={INFO}
          >
            {t('your_collections.show.adhoc_warning')}
          </Alert>
        )}
      </div>
      <CollectionActions
        collectionId={collectionId}
        collectionType={collectionType}
        collectionEndDate={collectionEndDate}
      />
    </div>
  )
}

export default memo(MyCollectionWrapper)
