import { COLLECTION_CLAIM_FAIL, COLLECTION_CLAIM_SUCCESS } from '@redux/actionTypes'
import { selectCollectionWithStoreAndBusinessById } from '@redux/selectors/collections'
import { trackAdEvent } from 'services/Analytics'
import { COLLECTION_TYPES } from 'config/collections'
import axios from 'helpers/axios'
import Debug from 'helpers/debug'
import { loggableError } from 'helpers/errors'
import { formatDateToTime24, formatShortDateAndTime24 } from 'helpers/time'

const debug = Debug('redux:thunks:collections:claimCollection')

const claimCollection = (collectionId, hasCollectionOnSameDay) => {
  return (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      const failedClaim = (error) => {
        const reason = loggableError(error)
        debug(`Failed claiming collection ${collectionId}`, reason)
        dispatch({
          type: COLLECTION_CLAIM_FAIL,
          payload: {
            errors: reason.data.errors?.base,
          },
        })
        reject(reason)
      }

      const collection = selectCollectionWithStoreAndBusinessById(getState(), collectionId)

      return axios
        .patch(`/api/v1/collections/${collectionId}/claim`)
        .then(() => {
          trackAdEvent('COLLECTION_CLAIM_MODAL', {
            // used for FB
            id: collectionId,
            claimed_as: COLLECTION_TYPES.ONGOING,
          })
          dispatch({
            type: COLLECTION_CLAIM_SUCCESS,
            payload: {
              id: collectionId,
            },
            meta: {
              analytics: {
                eventName: 'COLLECTION_CLAIM',
                eventPayload: {
                  business: collection.business.name,
                  business_id: collection.business.id,
                  store_id: collection.storeId,
                  frequency: collection.frequency,
                  location: collection.store.name,
                  nextAvailableSlotDate: formatShortDateAndTime24(collection.nextCollection),
                  nextAvailableSlotTime: formatDateToTime24(collection.nextCollection),
                  type: collection.type,
                  spaceCheckboxDisplayed: hasCollectionOnSameDay,
                  claimed_as: COLLECTION_TYPES.ONGOING,
                },
              },
            },
          })
          resolve({ success: true, collectionId })
        })
        .catch(failedClaim)
    })
  }
}

export default claimCollection
