import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import Button from 'components/common/Button'
import SceneHeader from 'components/common/SceneHeader'
import { Spacer } from 'components/layout/primitives'

const DEFAULT_ERROR_CODE = '404'

const ERROR_NAMES = {
  400: 'clientError',
  401: 'unauthorized',
  404: 'notFoundError',
  500: 'internalError',
}

const ErrorScene = () => {
  const { t } = useTranslation()
  const { errorCode } = useParams() || {}

  const errorKey = () => ERROR_NAMES[errorCode] || ERROR_NAMES[DEFAULT_ERROR_CODE]

  return (
    <div className="container">
      <Spacer h="40px" />
      <SceneHeader title={t(`errors.${errorKey()}.title`)} />
      <Spacer h="40px" />
      <div className="card">
        <div className="card-content">
          <p dangerouslySetInnerHTML={{ __html: t(`errors.${errorKey()}.description`) }} />
        </div>
        <div className="card-action">
          <Button href="/">{t(`errors.${errorKey()}.button`)}</Button>
        </div>
      </div>
    </div>
  )
}

export default ErrorScene
