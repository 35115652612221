type Props = {
  h?: string
  w?: string
}

const Spacer = ({ h = '0', w = '0' }: Props) => {
  return <div style={{ height: h, width: w }} />
}

export default Spacer
