import { memo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { MdHelpOutline, MdHistory, MdLoop } from 'react-icons/md'
import { useAppDispatch, useAppSelector } from '@redux/hooks'
import fetchFeatureFlags from '@redux/thunks/application/fetchFeatureFlags'
import fetchUserPreferences from '@redux/thunks/preferences/fetchUserPreferences'
import { trackEvent } from 'services/Analytics'
import { MESSAGE } from 'config/alert'
import { FETCHING_STATUSES } from 'config/api'
import Debug from 'helpers/debug'
import { useFetchAvailableCollectionsWithStoresAndBusinesses } from 'hooks/collections'
import AvailableCollectionsList from 'components/AvailableCollections/AvailableCollectionsList'
import CollapsibleAlert from 'components/common/CollapsibleAlert'
import LoadingSpinner from 'components/common/LoadingSpinner'
import SceneHeader from 'components/common/SceneHeader'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import styles from './AvailableCollectionsScene.module.scss'

const debug = Debug('components:collections:available-collections')

const AvailableCollectionsScene = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { availableCollections, hasCollectionsFetched } =
    useFetchAvailableCollectionsWithStoresAndBusinesses()
  const currentUserFetchingStatus = useAppSelector((state) => state.users.currentUserFetchingStatus)

  useEffect(() => {
    if (hasCollectionsFetched === true && availableCollections.length === 0) {
      trackEvent('LOAD_AVAILABLE_COLLECTIONS', {
        businesses: 0,
        availableCollections: 0,
        ongoingCollections: 0,
        adhocCollections: 0,
        temporaryCollections: 0,
      })
      trackEvent('NO_COLLECTIONS_AVAILABLE')
    }
  }, [hasCollectionsFetched])

  useEffect(() => {
    dispatch(fetchUserPreferences()).catch(debug)
    dispatch(fetchFeatureFlags()).catch(debug)
  }, [])

  return (
    <>
      <SceneHeader title={t('collection_slots.index.title')} />
      {hasCollectionsFetched === true && availableCollections.length > 0 && (
        <CollapsibleAlert
          icon={<MdHelpOutline />}
          title={t('available-collections.scene.info-header')}
          description={t('available-collections.scene.description1')}
          variant={MESSAGE}
          className={styles.info}
        >
          <p>{t('available-collections.scene.description2')}</p>
          <p className={styles.type}>
            <span>
              <MdLoop className={styles.iconOngoing} />
            </span>
            <span>
              <b>{t('collection_slots.index.type_filter.ongoing')}</b>
              {t('available-collections.scene.ongoing')}
            </span>
          </p>
          <p className={styles.type}>
            <span>
              <MdHistory className={styles.iconAdhoc} />
            </span>
            <span>
              <b>{t('collection_slots.index.type_filter.adhoc')}</b>
              {t('available-collections.scene.adhoc')}
            </span>
          </p>
          <p className={styles.more}>
            <SetInnerHtmlSafe>
              {t('available-collections.scene.more', {
                moreHighlightedLink: ` <a href=${t(
                  'available-collections.scene.more-link-url',
                )}>${t('available-collections.scene.more-link-text')}</a>`,
              })}
            </SetInnerHtmlSafe>
          </p>
        </CollapsibleAlert>
      )}

      {hasCollectionsFetched === true && currentUserFetchingStatus === FETCHING_STATUSES.SUCCESS ? (
        <AvailableCollectionsList availableCollections={availableCollections} />
      ) : (
        <LoadingSpinner containerHeight="400px" />
      )}
    </>
  )
}

export default memo(AvailableCollectionsScene)
