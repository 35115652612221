import { useLayoutEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdHelpOutline, MdOutlineCancel, MdOutlineStackedBarChart } from 'react-icons/md'
import clsx from 'clsx'
import { useOnClickOutside, useResizeObserver } from 'usehooks-ts'
import { formatDate, dayOfWeekMonthAndDayFormat } from 'helpers/time'
import CollectionSizeEmptyState from 'images/collection-size-empty-state.png'
import InformationIcon from 'components/AvailableCollections/AvailableCollectionInformation/InformationIcon'
import Card from 'components/common/Cards/Card'
import styles from './CollectionSize.module.scss'

export type CollectionSizeDataItem = {
  id: number
  article_items_count: number
  articles_count: number
  puas_count: number
  requests_count: number
  start: string
  status: 'offered' | 'unconfirmed'
}

type Props = {
  collectionSizeItems: CollectionSizeDataItem[]
  collectionType: string
}

const CollectionSize = ({ collectionSizeItems, collectionType }: Props) => {
  const { t } = useTranslation()
  const cardRef = useRef<HTMLDivElement>()
  const overlayRef = useRef<HTMLDivElement>()
  const [elementPosition, setElementPosition] = useState<any>()
  const [isOverlayVisible, setIsOverlayVisible] = useState(false)

  useOnClickOutside(overlayRef, () => setIsOverlayVisible(false))

  const { width = 0 } = useResizeObserver({ ref: cardRef })
  useLayoutEffect(() => {
    if (!cardRef.current) {
      return
    }

    const updatePosition = () => {
      setElementPosition(cardRef.current.getBoundingClientRect())
    }
    window.addEventListener('resize', updatePosition)
    window.addEventListener('scroll', updatePosition)
    updatePosition()
    return () => {
      window.removeEventListener('resize', updatePosition)
      window.removeEventListener('scroll', updatePosition)
    }
  }, [])

  const handleHelpClick = () => {
    setIsOverlayVisible(true)
  }

  const maxCollectionSize = Math.max(
    ...collectionSizeItems.map((collectionSize) => collectionSize.article_items_count),
  )
  const hasEmptyCollection = collectionSizeItems.some(
    (collectionSize) => collectionSize.article_items_count === 0,
  )

  return (
    <>
      <div className={styles.root}>
        <div className={styles.heading}>
          <InformationIcon
            Icon={MdOutlineStackedBarChart}
            collectionType={collectionType}
          />
          <strong>{t('collection-slots.collection-size.heading')}</strong>
        </div>
        <div ref={cardRef}>
          <div className={styles.card}>
            <div className={styles.topSection}>
              <p className={styles.label}>{t('collection-slots.collection-size.dates-label')}</p>
              <span className={styles.helpIcon}>
                <MdHelpOutline
                  onClick={handleHelpClick}
                  data-testid="help-icon"
                />
              </span>
            </div>

            {collectionSizeItems.length === 0 ? (
              <div className={styles.emptyState}>
                <img
                  src={CollectionSizeEmptyState}
                  alt={t('collection-slots.collection-size.not-enough-info')}
                />
                <p>{t('collection-slots.collection-size.not-enough-info')}</p>
              </div>
            ) : (
              <div className={styles.collectionSizeList}>
                {collectionSizeItems.map((collectionSize) => {
                  return (
                    <div
                      className={styles.collectionSizeItem}
                      key={collectionSize.start}
                    >
                      <time>{formatDate(collectionSize.start, dayOfWeekMonthAndDayFormat)}</time>
                      {collectionSize.article_items_count === 0 ? (
                        <div className={styles.sizeBarContainer}>
                          <div className={clsx(styles.sizeBar, styles.empty)} />
                          <span className={clsx(styles.itemCount, styles.empty)}>
                            {t('collection-slots.collection-size.zero-items')}
                          </span>
                        </div>
                      ) : (
                        <div className={styles.sizeBarContainer}>
                          <div
                            className={styles.sizeBar}
                            style={{
                              width: (collectionSize.article_items_count / maxCollectionSize) * 100,
                            }}
                          />
                          <span className={styles.itemCount}>
                            {t('collection-slots.collection-size.items', {
                              count: collectionSize.article_items_count,
                            })}
                          </span>
                        </div>
                      )}
                    </div>
                  )
                })}
              </div>
            )}

            {hasEmptyCollection && (
              <p className={styles.emptyCollectionExplainer}>
                {t('collection-slots.collection-size.empty-collection-explainer')}
              </p>
            )}
          </div>
        </div>
      </div>

      {isOverlayVisible && (
        <div className={styles.overlayBackground}>
          <div ref={overlayRef}>
            <Card
              className={styles.overlay}
              style={{ width, marginLeft: elementPosition?.x, marginTop: elementPosition?.y }}
            >
              <div className={styles.topSection}>
                <h3 className={styles.overlayTitle}>
                  {t('collection-slots.collection-size.overlay-title')}
                </h3>
                <button
                  className={styles.closeButton}
                  onClick={() => setIsOverlayVisible(false)}
                  type="button"
                >
                  <MdOutlineCancel data-testid="closeIcon" />
                </button>
              </div>
              <p className={styles.overlayContent}>
                {t('collection-slots.collection-size.overlay-content')}
              </p>
            </Card>
          </div>
        </div>
      )}
    </>
  )
}

export default CollectionSize
