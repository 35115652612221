import clsx from 'clsx'
import linkifyHtml from 'linkify-html'
import SetInnerHtmlSafe from 'components/common/SetInnerHtmlSafe'
import styles from './Note.module.scss'

type NoteProps = {
  title: string
  note: string
  className?: string
}

const Note: React.FC<NoteProps> = ({ title, note, className }) => {
  const linkifiedNote = linkifyHtml(note)

  return (
    <div className={clsx(className, styles.wrapper)}>
      <h3 className={styles.title}>{title}</h3>
      <span className={styles.text}>
        <SetInnerHtmlSafe>{linkifiedNote}</SetInnerHtmlSafe>
      </span>
    </div>
  )
}

export default Note
